import React from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { Typography, Icon, Divider } from 'antd';
import classNames from 'classnames';
import Footer from '../footer';
import RightMenu from '../../components/Partial/MenuInfo/RightMenu';
import Shortcuts1 from '../../assets/images/screenshots/s-1.png';
import Shortcuts2 from '../../assets/images/screenshots/s-2.png';
import Shortcuts3 from '../../assets/images/screenshots/s-3.png';
import Zoom from 'react-reveal/Zoom';
/*typography */
const { Title } = Typography;
/*typography */

class Ebox extends React.Component
{
   render()
   {
       return(
            <div className="wrapper">
                <Zoom out>
                <Grid fluid className="search-sp">
                    <Grid container>
                        <Row>
                            <Col xs={12} sm={12}>
                                <Title level={4} className={classNames("search-title")}>how to use shortcuts?</Title>
                            </Col>
                        </Row>
                    </Grid>
                </Grid>
                <Grid fluid className={classNames("search-btm mt-20 mb-20 col-p")}>
                    <Grid container>
                        <Row>
                            <Col xs={12} sm={9} className={classNames("text-left")}>
                                <Title level={4} className={classNames("search-subtitle pb-0")}>
                                     What is Shortcuts?</Title>
                                <Divider />
                                <p>Files & Folders that have a long path and also be accessed by creating shortcuts to the specific files or folders.</p>

                                <div className="text-center"><img src={Shortcuts1} className="mw-100" alt="Shortcuts" /></div>

                                <Title level={4} className={classNames("search-subtitle pb-0")}>
                                How to delete only the shortcuts but not the notes?</Title>

                                <Divider />

                                <p>You can delete the shortcuts but not the notes by selecting this option.</p>

                                <div className="text-center"><img src={Shortcuts2} className="mw-100" alt="Shortcuts" /></div>

                                <Title level={4} className={classNames("search-subtitle pb-0")}>
                                How to delete notes form the notebook?</Title>
                                <Divider />

                                <p>This option deletes the note from the notebook by simply selecting this option.</p>
                                
                                <div className="text-center"> <img src={Shortcuts3} className="mw-100" alt="Shortcuts" /></div>

                                <Divider />
                            </Col>

                            <Col xs={12} sm={3} className={classNames("text-left", "ques-bk")}>
                                <Title level={4} className={classNames("ques-title")}>
                                <Icon type="menu" className="ques-icon"/>Recently viewed articles</Title>
                                
                                <RightMenu />
                            </Col>
                        </Row>
                        
                    </Grid>
                </Grid>
                </Zoom>
                {((localStorage.getItem('user_signId')>0) ? '' : <Footer />)}
               
            </div>
       );
   }
}

export default Ebox;