import React from 'react';
import {connect} from "react-redux";
import { Menu, Icon } from 'antd';
import {Link} from "react-router-dom";
import { Drawer } from 'antd';
import {baseURL}  from '../../axiosConfig';
import IntlMessages from "../../../util/IntlMessages";
import { CloudFilled, CustomerServiceFilled, KeyOutlined  } from '@ant-design/icons';
const { SubMenu } = Menu;

class Leftmenu1 extends React.Component {
/*drawer */
  state = { visible: false };

  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    }); 
  };
/*drawer */
handleMenuClick = row => e => {
  if(row==2){
      localStorage.removeItem('breadcrumbsPath');
  }    
}
  render() {
    let driveLink='';
    if((this.props.access_token!='') && (this.props.access_token!=null) && (this.props.access_token!='null'))
   {
         driveLink = <Link to="/GoogleDrive"><Icon type="google" /><IntlMessages id="sidebar.GoogleDrive"/></Link>;
   }   else {
         driveLink = <a href={baseURL+'api/Oauth2callback/'+this.props.user_id}><Icon type="google" /><IntlMessages id="sidebar.GoogleDrive"/></a>;
   }  
    return (
        <div className="resmenu">
            <span className="ml-10"><Icon className="d-block" style={{display: 'none' }} onClick={this.showDrawer} type="menu" /></span> 
            <div className="draw-body">{/* drawer */}
                <Drawer
                  placement="left"
                  closable={false}
                  onClose={this.onClose}
                  visible={this.state.visible}
                  maskClosable={true}
                >
                  <div className="p-0">
                      <Menu className="innersidemenu d-block">
                          <SubMenu className="mobile_view_menu"
                              key="sub1"
                              title={
                                <span>
                                  <Icon type="appstore" />
                                  <span>Profile</span>
                                </span>
                              }
                            >
                              <Menu.Item key="0">
                                  <Link to="/Profile"><Icon type="user" className="mr-10" />Profile</Link>
                              </Menu.Item>
                              <Menu.Item key="1">
                                  <Link to="/Apikey"><KeyOutlined />Api key</Link>
                              </Menu.Item>
                              <Menu.Item key="3">
                                  <Link to="/"><Icon type="question-circle" className="mr-10" />Help Center</Link>
                              </Menu.Item>
                             {/*  <Menu.Item key="4">
                                  <Link to="/"><Icon type="setting" className="mr-10" />Setting</Link>
                              </Menu.Item> */}
                              <Menu.Item key="5">
                                  <Link to="/Reportbug"><Icon type="setting" className="mr-10" />Report a Problem</Link>
                              </Menu.Item>
                              <Menu.Item key="6">
                                  <Link to="/Userbymail"><Icon type="setting" className="mr-10" />Create User By E-mail</Link>
                              </Menu.Item>
                              <Menu.Item key="7" onClick={() => this.props.userSignOut()}>
                                  <Link to="/"><Icon type="logout" className="mr-10" />Logout</Link>
                              </Menu.Item>
                          </SubMenu>

                         {/*  <Menu.Item key="1" onClick={this.onClose}>
                          <Link to="/file"><Icon type="home" />
                            <span>Homeeee</span></Link>
                          </Menu.Item> */}
                          <Menu.Item key="2" onClick={this.onClose}>
                          <Link to="/file"><Icon type="folder" />
                            <span>File</span></Link>
                          </Menu.Item>
                          <Menu.Item key="3" onClick={this.onClose}>
                          <Link to="/Notes"><Icon type="calendar" />
                            <span>Notes</span></Link>
                          </Menu.Item>
                          {/* <Menu.Item key="4" onClick={this.onClose}>
                          <Link to="/Notebook"><Icon type="calendar" />
                            <span>Notebook</span></Link>
                          </Menu.Item>
                          <Menu.Item key="5" onClick={this.onClose}>
                            <Link to="/Markeddd"><Icon type="calendar" />
                            <span>Marked</span></Link>
                          </Menu.Item>
                          <Menu.Item key="6" onClick={this.onClose}>
                            <Link to="/Photos"><Icon type="file-image" />
                            <span>Photos</span></Link>
                          </Menu.Item>
                          <Menu.Item key="7" onClick={this.onClose}>
                            <Link to="/Group"><Icon type="usergroup-delete" />
                            <span>Group</span></Link>
                          </Menu.Item> */}
                          <Menu.Item key="8" onClick={this.onClose}>
                            <Link to="/Trash"><Icon type="delete" />
                            <span>Trash</span></Link>
                          </Menu.Item>
                          <Menu.Item key="9" onClick={this.onClose}>
                            <Link to="/SharePoint"><Icon type="windows" />
                            <span>Share Point</span></Link>
                          </Menu.Item>
                          <Menu.Item key="10" onClick={this.onClose}>
                            <Link to="/Dropbox"><Icon type="dropbox" />
                            <span>Dropbox</span></Link>
                          </Menu.Item>
                          <Menu.Item key="11">
                                    {driveLink}
                          </Menu.Item>
                          <Menu.Item key="12">
                              <Link to="/FileCloud"><CloudFilled />
                                <IntlMessages id="sidebar.FileCloud"/></Link>
                          </Menu.Item>
                      </Menu>
                  </div>
                </Drawer>
            </div> {/* drawer */}
      </div>
    );
  }
}
const mapStateToProps = ({settings, auth}) => {
  const {navStyle, themeType, locale, pathname} = settings;
  const {authUser,displayName, user_id, access_token} = auth;
  return {navStyle, themeType, locale, pathname, authUser,displayName,user_id, access_token}
};

export default connect(mapStateToProps)(Leftmenu1);
