//let baseURL = 'http://localhost/hexanotes/server/public/';
let baseURL = 'https://server.hexanotes.com/';
let fileURL ='https://files.hexanotes.com/';
let driveURL = 'https://drive.hexanotes.com/';
let dropboxURL = 'https://dropbox.hexanotes.com/';
let cloudURL = 'https://filecloud.hexanotes.com/';
let sharepointURL = 'https://sharepoint.hexanotes.com/';
//export default baseURL;
export {
    baseURL,
    fileURL,
    driveURL,
    dropboxURL,
    cloudURL,
    sharepointURL
  };