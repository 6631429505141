import React from 'react';
import { Menu, Icon, Dropdown, message, Tooltip, Modal, Input, Button, Form, Upload } from 'antd';
import classNames from 'classnames';
import { Grid, Row, Col } from 'react-flexbox-grid';
import Folderpic from '../../../assets/images/folders.png';
import liimg from '../../../assets/images/rose.jpeg';
import dropboxApi from '../../../assets/images/dropbox-api.png';
import { Link } from 'react-router-dom';

/*tooltop*/
const text = <span>Shared By You</span>;
//const buttonWidth = 70;
/*tooltop*/

function handleButtonClick(e) {
  message.info('Click on left button.');
  console.log('click left button', e);
}
class Sidemenu extends React.Component {
  state = {
    modal1Visible: false,
    modal2Visible: false,
    modal3Visible: false,
    newfolder: false,
  };

  setModal1Visible(modal1Visible) {
    this.setState({ modal1Visible });
  }

  setModal2Visible(modal2Visible) {
    this.setState({ modal2Visible });
  }

  setModal3Visible(modal3Visible) {
    this.setState({ modal3Visible });
  }

  setUploadfilesVisible(uploadfiles) {
    this.setState({ uploadfiles });
  }

  setNewfoldervisible(newfolder) {
    this.setState({ newfolder });
  }

  
  state = {
    openKeys: ['sub1'],
  };

  onOpenChange = openKeys => {
    const latestOpenKey = openKeys.find(key => this.state.openKeys.indexOf(key) === -1);
    if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      this.setState({ openKeys });
    } else {
      this.setState({
        openKeys: latestOpenKey ? [latestOpenKey] : [],
      });
    } 
  };

  normFile = e => {
    console.log('Upload event:', e);
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };


  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {

      }
    });
}

  render() {
    const { getFieldDecorator } = this.props.form;
    const { loading } = this.state;
    
    return (
        <div className="wrapper" style={{marginTop:'10%'}}>
          <Grid fluid className="pl-0" key="1"> 
        <h2 class="post-title">How to Get Your Dropbox API Access Token</h2>
        <h3>1. &nbsp;Create an app in your Dropbox account</h3>
        <div id="items">
        <ol>
            <li>Go to&nbsp;<a href="https://www.dropbox.com/developers/apps/create"  target="_blank">https://www.dropbox.com/developers/apps/create</a></li>
            <li>Authorize, if you weren’t.</li>
            <li>Choose Dropbox API on the first step.</li>
            <li>Choose Full Dropbox access on the second.</li>
            <li>Give your app a name. That name will become a folder in your Dropbox account.</li>
            <li>Push ‘Create app’ button.</li>
        </ol>
    <p><a href="JavaScript:Void(0);" rel="attachment wp-att-402"></a><img className={classNames} src={dropboxApi} alt="img" /></p>
        <h3>2. &nbsp;Generate access token</h3>
        <p>You’ll be presented with your app’s settings.</p>
        <ol>
            <li>Scroll down to ‘OAuth 2’ block and <strong>hit ‘Generate’ </strong>button near ‘Generated access token’ text.</li>
            <li>After the token is generated you’ll see a string of letters and numbers, which looks something like this:</li>
        </ol>
        <blockquote><p>fkeqazcnlytdghf2hgfjh41hfghjhgk1jhk11fhyiko11ghkllre6ooo111fgheww</p></blockquote> 
        </div>  
        </Grid>       
         </div>
    );
  }
}

export default Form.create()(Sidemenu);
