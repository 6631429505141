import React from "react";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import {ConnectedRouter}  from "react-router-redux";
import configureStore, {history} from "../appRedux/store";
import asyncComponent from "../util/asyncComponent";
import UserManual from '../components/Admin/Dropbox/Fileinnerpage';
const App = ({match}) => (
  <ConnectedRouter history={history}>
  <div className="gx-main-content-wrapper">
    <Switch>
      <Route path={`${match.url}hexaDocument/:docId`} component={asyncComponent(() => import('../components/Admin/DocumentEditor/index'))}/>
      <Route path={`${match.url}file`} component={asyncComponent(() => import('../components/Admin/Files/filess'))}/>
      <Route path={`${match.url}Notes`} component={asyncComponent(() => import('../components/Admin/Notes/notess'))}/>
      <Route path={`${match.url}Notebook`} component={asyncComponent(() => import('../components/Admin/Notebook/notebookss'))}/>
      <Route path={`${match.url}Markeddd`} component={asyncComponent(() => import('../components/Admin/Marked/Markeddd'))}/>
      <Route path={`${match.url}Photos`} component={asyncComponent(() => import('../components/Admin/Photos/photoss'))}/>
      <Route path={`${match.url}Albums`} component={asyncComponent(() => import('../components/Admin/Photos/album'))}/>
      <Route path={`${match.url}AlbumDetail`} component={asyncComponent(() => import('../components/Admin/Photos/album-1'))}/>
      <Route path={`${match.url}Group`} component={asyncComponent(() => import('../components/Admin/Group/group'))}/>
      <Route path={`${match.url}Remainder`} component={asyncComponent(() => import('../components/Admin/Remainder/remainder'))}/>
      <Route path={`${match.url}Recorder`} component={asyncComponent(() => import('../components/Admin/Recorder/Recorder'))}/>
      <Route path={`${match.url}Profile`} component={asyncComponent(() => import('../components/Admin/Dropdownpages/profile'))}/>
      <Route path={`${match.url}Apikey`} component={asyncComponent(() => import('../components/Admin/Dropdownpages/Apikey'))}/>
      <Route path={`${match.url}Reportbug`} component={asyncComponent(() => import('../components/Admin/Dropdownpages/reportbug'))}/>
      <Route path={`${match.url}Trash`} component={asyncComponent(() => import('../components/Admin/Trash/trash'))}/>
      <Route path={`${match.url}SharePoint`} component={asyncComponent(() => import('../components/Admin/Sharepoints/Sharepoints'))}/>
      <Route path={`${match.url}Dropbox`} component={asyncComponent(() => import('../components/Admin/Dropbox/Dropbox'))}/>
      <Route path={`${match.url}userguide`} component={asyncComponent(() => import('../components/Admin/Dropbox/Fileinnerpage'))}/>
      <Route path={`${match.url}GoogleDrive`} component={asyncComponent(() => import('../components/Admin/GoogleDrive/googledrive'))}/>
      <Route path={`${match.url}Myrefpage`} component={asyncComponent(() => import('../components/Admin/myref/myrefpage'))}/>
      <Route path={`${match.url}Invoice`} component={asyncComponent(() => import('../components/Admin/pdf/invoice1'))}/>
      <Route path={`${match.url}Adminconsole`} component={asyncComponent(() => import('../components/Admin/Adminconsole/Insights'))}/>
      <Route path={`${match.url}Members`} component={asyncComponent(() => import('../components/Admin/Members/Members'))}/>
      <Route path={`${match.url}Billing`} component={asyncComponent(() => import('../components/Admin/Billing/Billing'))}/>
      <Route path={`${match.url}Working`} component={asyncComponent(() => import('../components/Admin/myref/working'))}/>
      <Route path={`${match.url}FileCloud`} component={asyncComponent(() => import('../components/Admin/Filecloud/Filecloud'))}/>
      {/* frondend routes*/}
      <Route path={`${match.url}helpsupports`} component={asyncComponent(() => import('../components/Partial/helpsupport'))}/>
      <Route path={`${match.url}UseFile`} component={asyncComponent(() => import('../components/Partial/how-to-use-e-Box'))}/>
      <Route path={`${match.url}UseGroups`} component={asyncComponent(() => import('../components/Partial/how-to-use-group'))}/>
      <Route path={`${match.url}UseNotebooks`} component={asyncComponent(() => import('../components/Partial/how-to-use-notebook'))}/>
      <Route path={`${match.url}UseLogins`} component={asyncComponent(() => import('../components/Partial/how-to-login'))}/>
      <Route path={`${match.url}UseProblems`} component={asyncComponent(() => import('../components/Partial/how-to-report-problem'))}/>
      <Route path={`${match.url}UseNote`} component={asyncComponent(() => import('../components/Partial/how-to-use-notes'))}/>
      <Route path={`${match.url}UsePhoto`} component={asyncComponent(() => import('../components/Partial/how-to-use-photos'))}/>
      <Route path={`${match.url}UseShortcut`} component={asyncComponent(() => import('../components/Partial/how-to-use-shortcuts'))}/>
      <Route path={`${match.url}UseRegisters`} component={asyncComponent(() => import('../components/Partial/how-to-register'))}/>
    </Switch>
  </div>
  </ConnectedRouter>
);

export default App;
