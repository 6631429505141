import React from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { Typography, Icon, Divider, Breadcrumb } from 'antd';
import classNames from 'classnames';
import Footer from '../footer';


/*typography */
const { Title } = Typography;
/*typography */

class Termservices extends React.Component
{
   render()
   {
       return(
            <div className="wrapper">
                <Grid fluid className="search-bk">
                    <Grid container>
                        <Row>
                            <Col xs={12} sm={12}>
                                <Title level={4} className={classNames("search-title")}>Terms and Conditions</Title>
                                <div className="bread-crumb mt-10 mb-10">
                                    <Breadcrumb>
                                        <Breadcrumb.Item><a href="/">Home</a></Breadcrumb.Item>

                                        <Breadcrumb.Item>Getting Started</Breadcrumb.Item>

                                        <Breadcrumb.Item><a href="/">Terms and Conditions</a></Breadcrumb.Item>
                                    </Breadcrumb>
                                </div>
                            </Col>
                        </Row>
                    </Grid>
                </Grid>
                <Grid fluid className={classNames("search-btm mt-20 mb-20 col-p")}>
                    <Grid container>
                        <Row>
                            <Col xs={12} className={classNames("text-left")}>
                                <Title level={4} className={classNames("search-subtitle pb-0")}><Icon type="menu"  className="icon-style"/>Terms and conditions</Title>
                                <Divider />
                                <p>All content on a FileDrive is protected by the United States and other laws on intellectual property. You'll identify a few different types of content in our service. It's your content. You can back up, host, store, share files on FileDrive, and collaborate and communicate with others using FileDrive Share.</p>
                                <p>The files you upload to FileDrive and the other resources are yours and yours. You permit us to use this material solely to do what is required to provide our services, including processing, displaying, reproducing, and distributing the materials.</p>
                                <p>You are accountable for the content you submit to FileDrive and bear any associated risks like intellectual property or other legal claims. By using FileDrive to store and share content, you represent that you have the necessary rights.</p>
                                <p>You have the opportunity to try out any of our services on a trial basis. Please note that we do not hold content outside the timeframe for such trial plans. Even if you want to move on to another method later, after the trial timeline expires, you can not recover the content that you store with us during the trial period.</p>
                                <p>All rights to Firedrive — except for third party content — are the exclusive property of FileDrive and its licensors. Nothing in these Terms requires you to use any of the trademarks, logos, domain names, or other distinctive features of FileDrive, except as otherwise allowed by law.</p>
                                <Title level={3}>Restricted activities</Title>
                                <ul>
                                    <li>Do not use FileDrive in a manner that violates any laws , regulations, ordinances or directives.</li>
                                    <li>Do not use FileDrive contrary to our policies.</li>
                                    <li>Do not use FileDrive to do anything threatening, abusive, harassing, defamatory, tortuous, obscene, profane or invasive to the privacy of another person.</li>
                                    <li>Do not interfere with the proper functioning of any FileDrive software , hardware or equipment.</li>
                                    <li>Do not engage in any conduct that inhibits the use or enjoyment of our services by anyone else, or that we determine may harm FileDrive or our users.</li>
                                    <li>Do not monitor or copy any material on FileDriver, either manually or by automated means without prior written consent.</li>
                                </ul>
                                <Title level={3}>Our Rights</Title>
                                <p>We always work to improve FileDrive and improve our services, so we reserve some rights. At our sole discretion, we may, at any time — with or without notice — change, delete or restrict access to our services and modify, suspend, or terminate a user account. FileDrive shall not be liable for any damages resulting from these actions.</p>
                                <Title level={3}>Third-party sites</Title>
                                <p>FileDrive contains links to websites and services that we do not own or control. We do not endorse or assume responsibility for any third-party sites, information, materials, products, or services.</p>
                                <Title level={3}>Security</Title>
                                <p>We take the safety of FileDrive very seriously. We 're working to protect your content and account, but we can't guarantee 100% that third parties won't be able to defeat our security measures. Please let us know if you find any compromise or unauthorized use of your account.</p>
                                <Title level={3}>Copyright</Title>
                                <p>Where appropriate, FileDrive may warn and suspend repeat infringements of copyright. In more severe cases, we may permanently close user accounts. It also explains how a person who has posted the contents can object to a notice of alleged infringement by submitting a court.<br />Please think carefully before sending us a copyright notice or counter-notice because the DMCA imposes legal and financial penalties for making false or false claims. If you are not sure whether you are the actual copyright holder or are authorized to act on behalf of the rights holder, please check with the attorney before submitting copyright or a counter-notice to us.</p>
                                <Title level={3}>Copyright infringement notice format</Title>
                                <ul>
                                    <li>The physical or electronic signature of the copyright owner or person is authorized to act on their behalf</li>
                                    <li>Identification of the copyrighted work claimed to have been infringed.</li>
                                    <li>Identification of the infringing material and information reasonably sufficient to allow us to locate the content on our services.</li>
                                    <li>Your contact information, including your address, phone number, and email address, the date that you send a notice. </li>
                                    <li>A statement that you have a good faith belief that the use of the material in the manner claimed is not permitted by the copyright owner, agent, or the law.</li>
                                    <li>A statement that the information in the notification is accurate and, under penalty of perjury, that you are authorized to act on behalf of the owner of the copyright.</li>
                                </ul>
                                <p>Please read our message carefully, because it will include information about the copyright notice that we have received and instructions on how to file a counter-notice.</p>
                                <b>Note: If you remove the re-posting material in response to a DMCA notification, we may permanently suspend your account. If you believe that contents have been deleted in error, consider filing a counter-notice instead of re-posting the material.</b>
                                <br /><br />
                                <Title level={3}>Things required to submit a counter-notice</Title>
                                <ul>
                                    <li>Physical or electronic signature (your full name will be typed).</li>
                                    <li>Identification of the material that has been removed or to which access has been disabled and the URL to which the article appeared before it was removed or disabled (description from the DMCA notice is sufficient).</li>
                                    <li>A statement under penalty of perjury that you have a good faith belief that the material has been removed or disabled as a result of an error or misidentification of the material to be removed or disabled.</li>
                                    <li>Your name, address, and telephone number, and a statement that you consent to the jurisdiction of the Federal District Court for the judicial district in which your address is located, or if your address is outside the United States, for any judicial area in which FileDrive may be found, and that you will accept the process from the person who provided the copyright notice or a notice to you.</li>
                                </ul>
                                <Title level={3}>Limitations of Liability</Title>
                                <p>To the fullest extent permitted by law, FileDrive shall not be liable for any indirect, incidental, special, consequential or punitive damages or for any loss of profits or revenues, whether directly or indirectly incurred, or for any loss of data, use, goodwill, etc. Or any other intangible loss resulting from (a) your access, use, inability to access, or inability to use FileDrive; (b) any third-party conduct or content on FileDrive; or (c) any unauthorized access, use, or alteration of your content.</p>
                                <p>(Some states do not allow these limitations, so this provision may not apply to you.)</p>
                                <Title level={3}>Arbitration</Title>
                                <p>Any disputes we would like to resolve easily. To that end, if you have any problems with FileDrive, please contact us and we will work with you in good faith to try to resolve the matter.</p>
                                <p>If the problem can not be dealt with informally, and if you are a resident of or have your principal place of business in the United States or Canada, you and FileDrive agree to resolve it. Any claims against each other through final and binding arbitration, including claims against FileDrive affiliates, officers, directors, employees and agents, and FileDrive affiliate officers , directors , employees and agents.</p>
                                <p>You and FileDrive agree to submit the dispute to a single arbitrator pursuant to the Commercial Arbitration Rules of the American Arbitration Association (AAA), including the Optional Rules for Emergency Preparedness and Supplementary Procedures for Consumer-Related Disputes, Or, by separate mutual agreement, to another arbitration body. The rules of the AAA and a description of the arbitration process are available at www.adr.org.</p>
                                <p>The arbitration shall take place in Kansas City , Missouri, or any other location to which both parties agree in writing. Allocation of fees and costs shall be determined in accordance with the AAA rules, except that FileDrive shall be reimbursed. You are entitled to all AAA administrative fees in disputes which are subject to the Supplementary Procedures for Consumer-Related Disputes, unless the arbitrator determines that a claim or counterclaim has been filed for harassment or is patently frivolous.</p>
                                <p>The arbitrator 's award shall include costs of arbitration, reasonable fees for attorneys and reasonable costs for expert witnesses and other witnesses. Any judgment of the arbitrator on the award may be brought before any court of competent jurisdiction. The Federal Arbitration Act governs any dispute between you and FileDrive. The arbitrator shall decide whether a dispute may be arbitrated.</p>
                                <p>The Federal Arbitration Act governs any dispute between you and FileDrive. If you opt out of the arbitration agreement (as stated below), or if the arbitration agreement is found to be inapplicable, or if you are neither a resident nor have a principal place of business in the United States or Canada, the arbitrator shall decide whether the dispute may be arbitral. You agree to settle any claim that you have with FileDrive solely in a state or federal court located in Kansas City, Missouri, and to submit to the personal jurisdiction of those courts for the purpose of litigating any such dispute.</p>
                                <p>Nothing shall prevent either party from seeking an injunctive or other equitable relief from the courts in matters relating to data security, intellectual property or unauthorized access to services.</p>
                                <Title level={3}>Your rights for arbitration</Title>
                                <p>You may opt out of the arbitration agreement. If you do so, neither you nor FileDrive may require the other party to take part in the arbitration proceedings. To opt out, you must notify FileDrive within 90 days of the date on which you first agreed to these Terms. Your written opt-out notice must include (1) your name and address of residence; (2) your email address and/or mobile phone number associated with your account; (3) a clear statement that you wish to opt out of this arbitration agreement; and (4) your signature.</p>
                                <Title level={3}>No class action</Title>
                                <p>You may bring disputes only on an individual basis and not in a class, consolidated or representative form of action. Administration of Law and Jurisdiction.</p>
                                <p>These Terms shall be governed by the laws of the State of Missouri, with the exception of its conflict of laws principles.</p>
                                <p>In the case of claims that are not subject to arbitration, each of us agrees to submit to the personal jurisdiction of a state court located in Kansas City , Missouri or the United States District Court of the Western District of Missouri.</p>
                                <Title level={3}>Modifications</Title>
                                <p>Terms may be changed from time to time. The date of the most recent revisions will always be https://FileDrive.it/legals of use, and older versions will be archived at FileDrive.com. If we make changes that we believe will substantially alter your rights, we will post revisions 7 days before they come into effect so that you can review them. You agree to accept any changes or revisions to the Terms by continuing to use FileDrive.</p>
                                <Title level={3}>Contact</Title>
                                <p>We welcome any questions , concerns and feedback you may have on these terms. If you have any suggestions for us, please contact our customer success team.</p>
                            </Col>
                        </Row>
                        
                    </Grid>
                </Grid>
                <Footer />
            </div>
       );
   }
}

export default Termservices;