import React, { Component } from "react";
import { Menu, Icon } from 'antd';
import { Link } from 'react-router-dom'; 
import { Link as Links,DirectLink,Element,Events,animateScroll as scroll,scrollSpy,scroller } from "react-scroll";
import 'antd/dist/antd.css';
import { Grid, Row, Col } from 'react-flexbox-grid';
import Frontlogo from "../../../assets/images/logo.png";
import Frontloginmobilemenu from '../../../components/Admin/Partial/frontloginmobilemenu';


class Navigation extends Component {
  state = {
      current: 'mail',
    };
    
  handleClick = e => {
      console.log('click ', e);
      this.setState({
        current: e.key,
      });
    };

render(){  
return (
  <div>
 
 <Grid container>
     <Row>
          <Col xs={12} sm={3}>
              <Link to="/index" className="mob-dis-show"><img src={Frontlogo} alt="Frontlogo" className="ptb-5"/></Link>
          </Col>
          <Col xs={12} sm={9}>
              <Frontloginmobilemenu />
          </Col>
     </Row>
   <Row className="mob-dis-none">
     <Col xs={12} sm={3} className="text-left media-center">
          <Link to="/index"><img src={Frontlogo} alt="Frontlogo" className="ptb-5"/></Link>
     </Col>
     <Col xs={12} sm={9} className="text-right d-flex">
        <Menu onClick={this.handleClick} 
            defaultSelectedKeys={['1']}
            defaultOpenKeys={['sub1']}
            mode="horizontal"
            theme="lite"
            inlineCollapsed={this.state.collapsed} className="menuhr-none">
          <Menu.Item key="login">
            <Link to={`${process.env.PUBLIC_URL}/signin`}><Icon type="login" /> Login</Link>
          </Menu.Item>

          <Menu.Item key="user">
          <Link to="/index?regRef=register"><Icon type="user" /> Register</Link>
          </Menu.Item>

          <Menu.Item key="question">
            <Link to={`${process.env.PUBLIC_URL}/helpsupport`}><Icon type="question" />  Help & Support</Link>
          </Menu.Item>
        </Menu>
     </Col>
   </Row>
 </Grid>
        
  </div>
);
}
}

export default Navigation;