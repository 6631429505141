import React from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { Typography, Icon, Divider } from 'antd';
import classNames from 'classnames';
import Footer from '../footer';
import RightMenu from '../../components/Partial/MenuInfo/RightMenu';
import Register1 from '../../assets/images/screenshots/e-register-header.png';
import Register2 from '../../assets/images/screenshots/e-register.png';

import Zoom from 'react-reveal/Zoom';
/*typography */
const { Title } = Typography;
/*typography */

class Ebox extends React.Component
{
   render()
   {
       return(
            <div className="wrapper">
                <Zoom out>
                <Grid fluid className="search-sp">
                    <Grid container>
                        <Row>
                            <Col xs={12} sm={12}>
                                <Title level={4} className={classNames("search-title")}>how to use Register?</Title>
                            </Col>
                        </Row>
                    </Grid>
                </Grid>
                <Grid fluid className={classNames("search-btm mt-20 mb-20 col-p")}>
                    <Grid container>
                        <Row>
                            <Col xs={12} sm={9} className={classNames("text-left")}>
                                <Title level={4} className={classNames("search-subtitle pb-0")}>
                                    Login?</Title>
                                <Divider />
                                <p>Once you have reached the index page you can select "Register" from one of the options in the top right corner of the screen.</p>
                                <img src={Register1} className="mw-100" alt="Register" />
                                <p>Once you select Register, type in your name, email ID and password, and continue to sign up for free.</p>
                                <img src={Register2} className="mw-100" alt="Register" />
                                <p>Once you have selected the SIGN UP FOR FREE option. You will be redirected to the login page. You can then type in your registered email ID and password to proceed.</p>
                            </Col>

                            <Col xs={12} sm={3} className={classNames("text-left", "ques-bk")}>
                                <Title level={4} className={classNames("ques-title")}>
                                <Icon type="menu" className="ques-icon"/>Recently viewed articles</Title>
                                <RightMenu />
                            </Col>
                        </Row>
                        
                    </Grid>
                </Grid>
                </Zoom>
                {((localStorage.getItem('user_signId')>0) ? '' : <Footer />)}
               
            </div>
       );
   }
}

export default Ebox;