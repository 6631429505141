import React, {Component} from "react";
import {connect} from "react-redux";
import {Avatar, Dropdown, Menu, Icon, Button, Modal, Form, Input, Row, Col} from "antd";
import {Link} from "react-router-dom";
import {userSignOut} from "../../appRedux/actions/Auth";
import { KeyOutlined } from '@ant-design/icons';
import {baseURL, fileURL} from '../../components/axiosConfig';

class UserProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userName:'',
      visible: false,
   
    };
  }
  /* state = {
    visible: false,
  }; */

  componentDidMount() {
    console.log('pic', localStorage.getItem('p_pic'));
    //const rememberMe = localStorage.getItem('rememberMe') === 'true';
    //const user = this.props.displayName;
    this.setState({ userName : this.props.displayName });
    //console.log('userName', localStorage.getItem('rememberMe') );
  }
  showModal = () => {
    this.setState({
      visible: true,
    });
  };
  handleCancel = () => {
    console.log('Clicked cancel button');
    this.setState({
      visible: false,
    });
  };
  handleok = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
     
  });
};

  render() {
    const { getFieldDecorator } = this.props.form;
    const { visible, confirmLoading, loading } = this.state;
    
/* dropdown */
const menu = (
  <Menu className="dpdn-color">
    <Menu.Item key="0">
        <Link to="/Profile"><Icon type="user" className="mr-10" />Profile</Link>
    </Menu.Item>
    <Menu.Item key="1">
        <Link to="/Apikey"><KeyOutlined className="mr-10" />Api Key</Link>
    </Menu.Item>
    <Menu.Item key="2">
        <Link to="/helpsupports"><Icon type="question-circle" className="mr-10" />Help Center</Link>
    </Menu.Item>
    {/* <Menu.Item key="2">
        <Link to="/"><Icon type="setting" className="mr-10" />Setting</Link>
    </Menu.Item> */}
    <Menu.Item key="3">
        <Link to="/Reportbug"><Icon type="setting" className="mr-10" />Report a Problem</Link>
    </Menu.Item>
    <Menu.Item key="4" onClick={this.showModal}>
        <Link to="/Userbymail"><Icon type="setting" className="mr-10" />Create User By E-mail</Link>
    </Menu.Item>
    <Menu.Item key="5" onClick={() => this.props.userSignOut()}>
        <Link to="/"><Icon type="logout" className="mr-10" />Logout</Link>
    </Menu.Item>
  </Menu>
)

return (

      <div className="gx-flex-row gx-align-items-center gx-mb-4 gx-avatar-row">
        {localStorage.getItem('p_pic')!=null ? <Avatar src={fileURL+'uploads/'+localStorage.getItem('p_pic')} />:''}
        <Dropdown overlay={menu} trigger={['click']}>
            <a className="ant-dropdown-link text-white" href="/#">
            {this.state.userName} <Icon type="down" />
            </a>
        </Dropdown>

            <Modal className="modal1"
                title="Send Email"
                visible={visible}
                onOk={this.handleOk}
                confirmLoading={confirmLoading}
                onCancel={this.handleCancel}
                keyboard={false}
                maskClosable={false}
                footer={[
                    <Button key="submit" type="primary" className="blue-btn" loading={loading} onClick={this.handleok}><Icon type="mail" />Email</Button>
                ]}
                >
                  <Row>
                    <Form layout="vertical">
                        <Col xs={12} sm={6}>
                            <Form.Item label="User Email" className="mb-0">
                            {getFieldDecorator('title', {
                              rules: [{ required: true, message: 'Please input the title of collection!' }],
                            })(
                            <Input placeholder="Enter Sharepoint User Name" />)}
                            </Form.Item>
                        </Col>
                    </Form>
                  </Row>
                </Modal>
      </div>

    )

  }
}
const wrappedformUserProfile = Form.create()(UserProfile);
const mapStateToProps = ({auth}) => {
  const {authUser,displayName, user_id} = auth;
  return {
    authUser,displayName,user_id}
};
export default connect(mapStateToProps, {userSignOut})(wrappedformUserProfile);
