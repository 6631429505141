import React, {Component} from 'react'
import { Form,  Input, Button, message } from 'antd';
import classNames from 'classnames';
import Footer from '../components/footer';
import { Grid, Col } from 'react-flexbox-grid';
import Title from 'antd/lib/typography/Title';
import axios from 'axios';
import {baseURL} from '../components/axiosConfig';
import { Link } from 'react-router-dom'; 
import {connect} from "react-redux";
import Zoom from 'react-reveal/Zoom';

import {
  hideMessage,
  showAuthLoader,
  userFacebookSignIn,
  userGithubSignIn,
  userGoogleSignIn,
  userSignIn,
  setInitUrl,
  userTwitterSignIn
} from "../appRedux/actions/Auth";
import CircularProgress from "../components/CircularProgress/index";
import queryString from 'query-string';


const base64url = require('base64-url')
class SignIn extends Component {
  setField(e) {
    this.setState({ [e.target.email]: e.target.value })
    this.setState({ [e.target.password]: e.target.value })
  }
  state = {
    email: '',
    password: '',
    submitted: false,
    fpassword:'',
    encryptedID:'',
    femail:'',
    UEmail:'',
  }
  
  componentDidMount() {
    document.title = "FileDrive - Login"
    if(this.props.location.search!=''){
      const values = queryString.parse(this.props.location.search)
      this.forgetHashKey(values);
      //let decodeval = base64url.decode(values.point);
      //this.setState({encryptedID : decodeval})
    }
   }
   forgetHashKey(value){
    let formData = new FormData();
      formData.append('fromMailKey', JSON.stringify(value))
      axios({
        method: 'post',
        url: baseURL+'api/getforgetHashKey',
        data: formData,
        config: { headers: { 'Content-Type': 'multipart/form-data' } }
      })
        .then((response) => {
          let mailKey = response.data.forget_hash_key;
          let mail = response.data.email;
          this.setState({ encryptedID: mail });
          
        });  

   }

  handleSubmit = (e) => {
  e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({ submitted: true }); 
        let formData = new FormData();
        formData.append('email', this.state.email)
        formData.append('password', this.state.password)
          axios({
            method: 'post',
            url: baseURL+'api/login',
            data: formData,
            config: { headers: { 'Content-Type': 'multipart/form-data' } }
          })
            .then((response) => {
              if (response.data.status=== 200){
              this.props.showAuthLoader();
              this.props.userSignIn(response.data);
              this.props.setInitUrl('', response.data.login_token);
              //message.success(response.data.msg);
              //this.props.history.push('/file');
              setTimeout(() => {
                let formData1 = new FormData();
                formData1.append('reactauthUser', this.props.authUser)
                formData1.append('larevlToken', response.data.login_token)
                formData1.append('user_id', response.data.user_id)
                axios({
                  method: 'post',
                  url: baseURL+'api/tokenSave',
                  data: formData1,
                  config: { headers: { 'Content-Type': 'multipart/form-data' } }
                })
              .then((response) => {
                     
                  });
              }, 5000);
              } else{
                 message.error('Email ID and password are mismatched!'.toString())
              }
              
            })
            .catch((response) => {
              //console.log(response)
            });  
      //console.log('Received values of form: ', values);
      }
    });
  };
  handleForget = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({ submitted: true }); 
        let encode =  base64url.encode(this.state.femail);
        let formData = new FormData();
        formData.append('email', this.state.femail)
        formData.append('emailencode', encode)
       // formData.append('user_id', this.props.user_id)
        formData.append('method', 'sendForgetMail')
       // formData.append('username', this.props.displayName) 
         axios({
            method: 'post',
            url: baseURL+'api/forgetPassword',
            data: formData,
            config: { headers: { 'Content-Type': 'multipart/form-data' } }
          })
            .then((response) => {
              if (response.data.status=== 1){
                message.success('Email sent successfully');
              } else{
                 message.error('Email ID is mismatched!'.toString())
              }
              
            })
            .catch((response) => {
              //console.log(response)
            });  
      
      }
    });
  }

  handleUpdate = (e) => {
    e.preventDefault();
    const { password, cpassword } = this.state;
   this.props.form.validateFieldsAndScroll(['UEmail'],(err, values) => {
     if (!err) {
       let formData = new FormData();
       formData.append('email', this.state.encryptedID)
       formData.append('password', this.state.password)
       formData.append('method', 'PasswordUpdate')
       if (this.state.password !== this.state.cpassword) {
           message.error("Passwords don't match");
       }else{
           axios({
           method: 'post',
           url: baseURL + 'api/forgetPassword',
           data: formData,
           config: { headers: { 'Content-Type': 'multipart/form-data' } }
           })
         .then((response) => {
            if (response.data.status===200){
             message.success(response.data.msg);
             this.setState({encryptedID : ''})
             this.props.history.push('/signin');
           } else {
             message.error('Unable to update'.toString())
           }
           })
         .catch((err) => {
           message.error('Unable to update'.toString())
         })
     }
   }
   });
   
 };
  componentDidUpdate() {
    document.title = "FileDrive - Admin"
    if (this.props.showMessage) {
      setTimeout(() => {
        this.props.hideMessage();
      }, 100);
    }
    if (this.props.authUser !== null) {
      this.props.history.push('/');
    }
  }
  hideComponent(val){
    this.setState({fpassword : val})
  }
  render() {
    const { getFieldDecorator } = this.props.form;
    const {showMessage, loader, alertMessage} = this.props;
    const { encryptedID } = this.state;
    let forgetForm;
    if(this.state.encryptedID!=''){
      forgetForm = <Form onSubmit={this.handleUpdate} className="login-form log_form_style white_bg_inner">
      <Title level={4} className={classNames("log-title")}>Forgot Password</Title>
        <Form.Item>
          {getFieldDecorator('encryptedID', {
            initialValue:  this.state.encryptedID,
            rules: [{ required: true, message: 'Please input your email!' }],
          })(
            <Input placeholder="Email" className="log-formcontrol" name="encryptedID"
            onChange={e => this.setState({ encryptedID: e.target.value })}/>,
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator('password', {
            rules: [{ required: true, message: 'Please input your Password!' }],
          })(
            <Input type="password" placeholder="Password" className="log-formcontrol" name="password" onChange={e => this.setState({ password: e.target.value })}/>,
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator('cpassword', {
            rules: [{ required: true, message: 'Please input your confirm Password!' }],
          })(
            <Input type="password" placeholder="Confirm Password" className="log-formcontrol" name="cpassword" onChange={e => this.setState({ cpassword: e.target.value })}/>,
          )}
        </Form.Item>
        <Form.Item>
          <Button htmlType="submit" className="sub_mit" block>
            Save
          </Button>
        </Form.Item>
        
      </Form>
    }
    return (
      <div className="wrapper">
        <Zoom out>
        <section className="login_bk_img">
          <Grid fluid>
            <Grid Row>
              <Col xs={12} sm={8} className="white_bg">
              {forgetForm }
              {this.state.fpassword == 'showForgetPassword' ? 
              <Form onSubmit={this.handleForget} className="login-form log_form_style white_bg_inner">
                <Title level={4} className={classNames("log-title")}>Foregt Password</Title>
                  <Form.Item>
                    {getFieldDecorator('femail', {
                      rules: [{ required: true, message: 'Please input your email!' }],
                    })(
                      <Input placeholder="Email" className="log-formcontrol" name="femail"
                      onChange={e => this.setState({ femail: e.target.value })}/>,
                    )}
                  </Form.Item>
                
                  <Form.Item>
                  <Button htmlType="submit" className="sub_mit" block>
                    Send
                  </Button>
                  </Form.Item>
                  <p className="log-text"><a onClick={() => this.hideComponent("showLogin")}>Sign In</a><a href="JavaScript:void(0);">Don't have an account?</a></p>
                </Form> 
          : (this.state.encryptedID =='') ? <Form onSubmit={this.handleSubmit} className="login-form log_form_style white_bg_inner">
                  <Title level={4} className={classNames("log-title")}>Login</Title>
                    <Form.Item>
                      {getFieldDecorator('Email', {
                        rules: [{ required: true, message: 'Please input your email!' }],
                      })(
                        <Input placeholder="Email" className="log-formcontrol" name="email"
                        onChange={e => this.setState({ email: e.target.value })}/>,
                      )}
                    </Form.Item>
                    <Form.Item>
                      {getFieldDecorator('password', {
                        rules: [{ required: true, message: 'Please input your Password!' }],
                      })(
                        <Input type="password" placeholder="Password" className="log-formcontrol" name="password" onChange={e => this.setState({ password: e.target.value })}/>,
                      )}
                    </Form.Item>
                    <Form.Item>
                      <Button htmlType="submit" className="sub_mit" block>
                        Login
                      </Button>
                    </Form.Item>
                    <p className="log-text"><a onClick={() => this.hideComponent("showForgetPassword")}>Forgot Password?</a>
                    <Link to="/index?regRef=register">Don't have an account?</Link></p>
                  </Form> : '' }
                  <p className="log-para">By clicking Create Account, Terms of Service and Privacy Policy.</p>
              </Col>
            </Grid>
          </Grid>
          {loader ?
              <div className="gx-loader-view">
                <CircularProgress/>
              </div> : null}
            {
              showMessage ? 
              message.error(alertMessage.toString()) : null}  
        </section>
        </Zoom>
        <Footer />
      </div>
    );
  }
}

const WrappedNormalLoginForm = Form.create()(SignIn);

const mapStateToProps = ({auth}) => {
  const {loader, alertMessage, showMessage, authUser} = auth;
  return {loader, alertMessage, showMessage, authUser}
};

export default connect(mapStateToProps, {
  userSignIn,
  setInitUrl,
  hideMessage,
  showAuthLoader,
  userFacebookSignIn,
  userGoogleSignIn,
  userGithubSignIn,
  userTwitterSignIn
})(WrappedNormalLoginForm);
