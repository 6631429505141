import React from 'react';
import { Menu, Icon } from 'antd';
import {Link} from "react-router-dom";
import { Drawer } from 'antd';

class Frontloginmobilemenu extends React.Component {
/*drawer */
  state = { visible: false };

  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };
 
  onClose = () => {
    this.setState({
      visible: false,
    });
  };
/*drawer */

  render() {
    return (
        <div className="resmenu resmenu-clone">
            {/* drawer */}
            <div className="draw-body">
            <Icon className="d-block" style={{display: 'none', color: '#55616c' }} onClick={this.showDrawer} type="menu" />
                <Drawer
                  placement="left"
                  closable={false}
                  onClose={this.onClose}
                  visible={this.state.visible}
                  maskClosable={true}
                >
                  <div className="p-0">
                      <Menu className="innersidemenu d-block frontresmenu">
                          <Menu.Item key="1" onClick={this.onClose}>
                            <Icon type="login" />
                            <span><Link to={`${process.env.PUBLIC_URL}/signin`}>Login</Link></span>
                          </Menu.Item>
                          <Menu.Item key="2" onClick={this.onClose}>
                            <Icon type="user" />
                            <span><Link to={`${process.env.PUBLIC_URL}/register`}>Register</Link></span>
                          </Menu.Item>
                          <Menu.Item key="3" onClick={this.onClose}>
                          <Icon type="question" />
                            <span><Link to={`${process.env.PUBLIC_URL}/helpsupport`}> Help & Support</Link></span>
                          </Menu.Item>
                      </Menu>
                  </div>
                </Drawer>
            </div> {/* drawer */}
      </div>
    );
  }
}

export default Frontloginmobilemenu;