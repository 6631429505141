import React from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { Typography, Icon, Divider } from 'antd';
import classNames from 'classnames';
import Footer from '../footer';
import RightMenu from '../../components/Partial/MenuInfo/RightMenu';
import Photos1 from '../../assets/images/screenshots/p-1.png';
import Photos2 from '../../assets/images/screenshots/p-2.png';
import Photos3 from '../../assets/images/screenshots/p-3.png';
import Zoom from 'react-reveal/Zoom';
/*typography */
const { Title } = Typography;
/*typography */

class Ebox extends React.Component
{
   render()
   {
       return(
            <div className="wrapper">
                <Zoom out>
                <Grid fluid className="search-sp">
                    <Grid container>
                        <Row>
                            <Col xs={12} sm={12}>
                                <Title level={4} className={classNames("search-title")}>How To Use Photos?</Title>
                            </Col>
                        </Row>
                    </Grid>
                </Grid>
                <Grid fluid className={classNames("search-btm mt-20 mb-20 col-p")}>
                    <Grid container>
                        <Row>
                            <Col xs={12} sm={9} className={classNames("text-left")}>
                                <Title level={4} className={classNames("search-subtitle pb-0")}>
                                    What is Timeline?</Title>
                                <Divider />
                                <p>Time let you save images at the time the photos are uploaded.</p>
                                <p><strong>Step 1:</strong>To Add photos, you need to go to e-box and once the photos are uploaded they appear on the timeline. To add these photos to a separate album, you need to select “Add to Album”</p>
                                <img src={Photos1} className="mw-100" alt="Photos" />
                                <p><strong>Step 2:</strong>Then a pop-up screen appears, you can then rename the album as you wish and then select create.</p>
                                <div className="text-center"><img src={Photos2} className="mw-100" alt="Photos" /></div>
                                <p><strong>Step 3:</strong>Once the album is created, it will appear in the album section.</p>
                                <img src={Photos3} className="mw-100" alt="Photos" />
                            </Col>

                            <Col xs={12} sm={3} className={classNames("text-left", "ques-bk")}>
                                <Title level={4} className={classNames("ques-title")}>
                                <Icon type="menu" className="ques-icon"/>Recently viewed articles</Title>
                                
                                <RightMenu />
                            </Col>
                        </Row>
                        
                    </Grid>
                </Grid>
                </Zoom>
                {((localStorage.getItem('user_signId')>0) ? '' : <Footer />)}
               
            </div>
       );
   }
}

export default Ebox;