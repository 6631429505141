import React, {Component} from "react";
import { Link } from 'react-router-dom';
import {Popover} from "antd";


class RightsideMenu extends Component {

  render() {
    const RightMenuOptions = (
     (localStorage.getItem('user_signId')>0) ? <ul className="ques-li">
     <li><Link to="/UseFile">How To Use Files?</Link></li>
     <li><Link to="/UseGroups">How to use group?</Link></li>
     <li><Link to="/UseNotebooks">How to use notebook?</Link></li>
     <li><Link to="/UseNote">How to use notes?</Link></li>
     <li><Link to="/UsePhoto">How to use photos?</Link></li>
     <li><Link to="/UseShortcut">How to use shortcuts?</Link></li> 
     <li><Link to="/UseLogins">How to login?</Link></li>      
     <li><Link to="/UseRegisters">How to register?</Link></li>      
     <li><Link to="/UseProblems">How to report problem?</Link></li>	  
 </ul> : <ul className="ques-li">
     <li><Link to="/UseFiles">How To Use Files?</Link></li>
     <li><Link to="/UseGroup">How to use group?</Link></li>
     <li><Link to="/UseNotebook">How to use notebook?</Link></li>
     <li><Link to="/UseNotes">How to use notes?</Link></li>
     <li><Link to="/UsePhotos">How to use photos?</Link></li>
     <li><Link to="/UseShortcuts">How to use shortcuts?</Link></li> 
     <li><Link to="/UseLogin">How to login?</Link></li>      
     <li><Link to="/UseRegister">How to register?</Link></li>      
     <li><Link to="/UseProblem">How to report problem?</Link></li>	  
 </ul>
      
    );

    return (
      <Popover overlayClassName="gx-popover-horizantal" placement="bottomRight" >
        {RightMenuOptions}
      </Popover>
    )

  }
}

export default RightsideMenu;
