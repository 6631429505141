import {all, call, fork, put, takeEvery} from "redux-saga/effects";
import {
  auth,
  
} from "../../firebase/firebase";
import {
  SIGNIN_USER,
  SIGNOUT_USER
  
} from "../../constants/ActionTypes";
import {showAuthMessage, userSignInSuccess, userSignOutSuccess} from "../../appRedux/actions/Auth";

const signInUserWithEmailPasswordRequest = async (email, password) =>
  await  auth.signInWithEmailAndPassword(email, password)
    .then(authUser => authUser)
    .catch(error => error);

    const signOutRequest = async () =>
    await  auth.signOut()
      .then(authUser => authUser)
      .catch(error => error);    

function* signInUserWithEmailPassword({payload}) {
  console.log('Usersagas', payload);
  const {email, password} = payload;
  try {
    const signInUser = yield call(signInUserWithEmailPasswordRequest, email, password);
    signInUser.displayName = payload.username;
    //console.log('Sagas', signInUser.user.uid+'~~~'+signInUser.displayName);
    if (signInUser.message) {
      yield put(showAuthMessage(signInUser.message));
    } else {
      localStorage.setItem('user_id', signInUser.user.uid);
      localStorage.setItem('user_name', signInUser.displayName);
      localStorage.setItem('user_signId', payload.user_id);
      localStorage.setItem('user_email', payload.email);
      localStorage.setItem('l_tok', payload.login_token);
      localStorage.setItem('dropbox_api_key', payload.dropbox_api_key);
      localStorage.setItem('access_token', payload.access_token);
      yield put(userSignInSuccess(signInUser.user.uid,payload.email,signInUser.displayName,payload.user_id,payload.login_token,payload.dropbox_api_key,payload.access_token));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* signOut() {
  try {
    const signOutUser = yield call(signOutRequest);
    if (signOutUser === undefined) {
      localStorage.removeItem('user_id');
      localStorage.removeItem('user_name');
      localStorage.removeItem('user_signId');
      localStorage.removeItem('l_tok');
      localStorage.removeItem('dropbox_api_key');
      localStorage.removeItem('access_token');
      localStorage.removeItem('breadcrumbsPath');
      localStorage.removeItem('p_pic');
      localStorage.removeItem('user_email');
      localStorage.removeItem('userIDS');
      localStorage.removeItem('uIDS');
      yield put(userSignOutSuccess(signOutUser));
    } else {
      yield put(showAuthMessage(signOutUser.message));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

export function* signInUser() {

  yield takeEvery(SIGNIN_USER, signInUserWithEmailPassword);
}

export function* signOutUser() {
  yield takeEvery(SIGNOUT_USER, signOut);
}


export default function* rootSaga() {
  yield all([fork(signInUser),
  fork(signOutUser)]);
}
