import React, {Component} from "react";
import {connect} from "react-redux";
//import URLSearchParams from 'url-search-params'
import {Redirect, Route, Switch} from "react-router-dom";
import {ConfigProvider} from "antd";
import {IntlProvider} from "react-intl";
import 'antd/dist/antd.css';
import AppLocale from "../../lngProvider";
import MainApp from "./MainApp";
import SignIn from "../SignIn";
import SignUp from "../SignUp";
import Navigation from "../../components/Partial/MenuInfo/Menu";
import HelpSupport from '../../components/Partial/helpsupport';
import Files from '../../components/Partial/how-to-use-e-Box';
import Group from '../../components/Partial/how-to-use-group';
import Notebook from '../../components/Partial/how-to-use-notebook';
import Notes from '../../components/Partial/how-to-use-notes';
import Photos from '../../components/Partial/how-to-use-photos';
import Shortcuts from '../../components/Partial/how-to-use-shortcuts';
import Login from '../../components/Partial/how-to-login';
import Register from '../../components/Partial/how-to-register';
import Problem from '../../components/Partial/how-to-report-problem';
//import logo from '../../assets/images/logo.png';
import Home from "../../components/index";
import Termsconditions from '../../components/Partial/termsconditions';
import Privacypolicy from '../../components/Partial/privacypolicy';
import {setInitUrl} from "../../appRedux/actions/Auth";
import {onLayoutTypeChange, onNavStyleChange, setThemeType} from "../../appRedux/actions/Setting";

import {
  THEME_TYPE_DARK
} from "../../constants/ThemeSetting";


const RestrictedRoute = ({component: Component, authUser, ...rest}) =>
  <Route
    {...rest}
    render={props =>
      authUser
        ? <Component {...props} />
        : <Redirect
          to={{
            pathname: '/signin',
            state: {from: props.location}
          }}
        />}
  />;

 
class App extends Component {

  constructor(props) {
    super(props)
    this.state = {
      authUser: '',
      pathname: '',
     }
   // this.showEdit = this.showEdit.bind(this);
   }
  
  componentWillMount() {
    if (this.props.initURL === '') {
      this.props.setInitUrl(this.props.history.location.pathname);
    }
    //const params = new URLSearchParams(this.props.location.search);
    }

    componentDidMount(){
      document.title = 'FileDrive - Create, Share & Collaborate your ideas across devices';
      /* console.log(this.props.history.location.pathname);
      console.log(this.props.authUser); */
      this.setState({ authUser: this.props.authUser });
      this.setState({ pathname: this.props.history.location.pathname });
    }

  render() {
    const {match, location, themeType, locale, authUser, initURL} = this.props;
    if (themeType === THEME_TYPE_DARK) {
      document.body.classList.add('dark-theme');
    }
    if (location.pathname === '/') {
      if (authUser === null) {
        return ( <Redirect to={'/index'}/> );
      } else if (initURL === '' || initURL === '/' || initURL === '/signin') {
        return ( <Redirect to={'/file'}/> );
      } else {
        return ( <Redirect to={initURL}/> );
      }
    }
    
    let menu='';
     if (authUser === null) {
      menu =  <Navigation />;
    } else {
      menu = '';
    }
    const currentAppLocale = AppLocale[locale.locale];
    return (
      <div className="App">
        
         { menu }
          
      <ConfigProvider locale={currentAppLocale.antd}>
        <IntlProvider locale={currentAppLocale.locale}
          messages={currentAppLocale.messages}>
            
          <Switch>
            <Route exact path='/index' component={Home}/>
            <Route path='/signin' component={SignIn}/>
            <Route path='/signup' component={SignUp}/>
            <Route path='/helpsupport' component={HelpSupport} />
            <Route path='/UseFiles' component={Files} /> 
            <Route path='/UseGroup' component={Group} />
            <Route path='/UseNotebook' component={Notebook} />
            <Route path='/UseNotes' component={Notes} />
            <Route path='/UsePhotos' component={Photos} />
            <Route path='/UseShortcuts' component={Shortcuts} />
            <Route path='/UseLogin' component={Login} />
            <Route path='/UseRegister' component={Register} />
            <Route path='/UseProblem' component={Problem} />
            <Route path='/Termsconditions' component={Termsconditions} />
            <Route path='/Privacypolicy' component={Privacypolicy} />
            <RestrictedRoute path={`${match.url}`} authUser={authUser}
                             component={MainApp}/>
          </Switch>
        </IntlProvider>
      </ConfigProvider>
      </div>
    )
  }
}

const mapStateToProps = ({settings, auth}) => {
  const {locale, navStyle, themeType, layoutType} = settings;
  const {authUser, initURL} = auth;
  return {locale, navStyle, themeType, layoutType, authUser, initURL}
};
export default connect(mapStateToProps, {setInitUrl, setThemeType, onNavStyleChange, onLayoutTypeChange})(App);
