import React from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { Input, Typography, Icon } from 'antd';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import Footer from '../../components/footer';

import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';

/*search*/
const { Search } = Input;
/*search*/

/*typography */
const { Title } = Typography;
/*typography */

class Helpcenter extends React.Component
{
   render()
   {
       //console.log('token', localStorage.getItem('user_signId'));
       return(
            <div className="wrapper">
                 <Zoom out>
                <Grid fluid className="search-bk">
                    <Grid container>
                        <Row>
                            <Col xs={12} sm={12}>
                                <div className={classNames("search-box")}>
                                    <Search onSearch={value => console.log(value)}/>
                                </div>
                                <Title level={4} className={classNames("search-title")}>Help & Learning</Title>
                            </Col>
                        </Row>
                    </Grid>
                </Grid>
                <Fade right>
                <Grid fluid className={classNames("search-btm", "mt-20", "mb-20")}>
                    <Grid container>
                        <Row>
                            <Col xs={12} className={classNames("text-left")}>
                                <Title level={4} className={classNames("search-subtitle")}><Icon type="menu"  className="icon-style"/>Help Articles</Title>
                            </Col>
                        </Row>
                        {((localStorage.getItem('user_signId')>0) ?
                        <Row className="ul-style mt-20">
                            <Col xs={12} sm={6}>
                                <ul>
                                    <li><Link to="/UseFile">How to use Files?</Link></li>
                                    <li><Link to="/UseGroups">How to use group?</Link></li>
                                    <li><Link to="/UseNotebooks">How to use notebook?</Link></li>
                                    <li><Link to="/UseLogins">How to login?</Link></li>
                                    <li><Link to="/UseProblems">How to report problem?</Link></li>
                                </ul>
                            </Col>
                            <Col xs={12} sm={6}>
                                <ul>
                                    <li><Link to="/UseNote">How to use notes?</Link></li>
                                    <li><Link to="/UsePhoto">How to use photos?</Link></li>
                                    <li><Link to="/UseShortcut">How to use shortcuts?</Link></li>
                                    <li><Link to="/UseRegisters">How to register?</Link></li>
                                </ul>
                            </Col>
                        </Row> : <Row className="ul-style mt-20">
                            <Col xs={12} sm={6}>
                                <ul>
                                    <li><Link to="/UseFiles">How to use Files?</Link></li>
                                    <li><Link to="/UseGroup">How to use group?</Link></li>
                                    <li><Link to="/UseNotebook">How to use notebook?</Link></li>
                                    <li><Link to="/UseLogin">How to login?</Link></li>
                                    <li><Link to="/UseProblem">How to report problem?</Link></li>
                                </ul>
                            </Col>
                            <Col xs={12} sm={6}>
                                <ul>
                                    <li><Link to="/UseNotes">How to use notes?</Link></li>
                                    <li><Link to="/UsePhotos">How to use photos?</Link></li>
                                    <li><Link to="/UseShortcuts">How to use shortcuts?</Link></li>
                                    <li><Link to="/UseRegister">How to register?</Link></li>
                                </ul>
                            </Col>
       </Row>) }
                    </Grid>
                </Grid>
                </Fade>
                </Zoom>
                {((localStorage.getItem('user_signId')>0) ? '' : <Footer />)}
               
            </div>
       );
   }
}

export default Helpcenter;