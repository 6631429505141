import React from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { Typography, Icon, Divider } from 'antd';
import classNames from 'classnames';
import Footer from '../footer';
import RightMenu from '../../components/Partial/MenuInfo/RightMenu';
import Notes1 from '../../assets/images/screenshots/nn-1.png';
import Notes2 from '../../assets/images/screenshots/nn-2.png';
import Notes3 from '../../assets/images/screenshots/nn-3.png';
import Notes4 from '../../assets/images/screenshots/nn-4.png';
import Notes5 from '../../assets/images/screenshots/nn-5.png';
import Notes6 from '../../assets/images/screenshots/nn-6.png';
import Notes7 from '../../assets/images/screenshots/nn-7.png';
import Notes8 from '../../assets/images/screenshots/nn-8.png';
import Notes9 from '../../assets/images/screenshots/nn-9.png';
import Zoom from 'react-reveal/Zoom';
/*typography */
const { Title } = Typography;
/*typography */

class Ebox extends React.Component
{
   render()
   {
       return(
            <div className="wrapper">
                <Zoom out>
                <Grid fluid className="search-sp">
                    <Grid container>
                        <Row>
                            <Col xs={12} sm={12}>
                                <Title level={4} className={classNames("search-title")}>how to use Notes?
                                </Title>
                            </Col>
                        </Row>
                    </Grid>
                </Grid>
                <Grid fluid className={classNames("search-btm mt-20 mb-20 col-p")}>
                    <Grid container>
                        <Row>
                            <Col xs={12} sm={9} className={classNames("text-left")}>
                                <Title level={4} className={classNames("search-subtitle pb-0")}> What are Notes?</Title>
                                <Divider />
                                
                                <p>Chunks of text, recorded and saved, which is then synced into your EasyNote account.</p>
                    
                                <div className="text-center"><img src={Notes1} className="mw-100" alt="Notes" /></div>
                                
                                <h1 class="bdr-btm">How to create a new note?</h1>
                                
                                <p><strong>Step 1:</strong> To create a note, select “Add Note”</p> 
                                                    
                                <img src={Notes2} className="mw-100" alt="Notes" />
                                
                                <p><strong>Step 2:</strong> Body of the Notes can be adjusted as you wish. You can use Notes to compose your text and save them in small documents. You can also change the Fonts/Size/Style/Layouts/bulletins/etc.</p>
                                
                                <img src={Notes3} className="mw-100" alt="Notes" />
                                
                                <h1 class="bdr-btm">What are the options available?</h1>
                                
                                <p><strong>Shortcut</strong></p>
                                
                                <p>Shortcuts can be created to reduce the time to find the particular file or folder and will then be found in the shortcut section.</p>
                                
                                <div className="text-center"><img src={Notes4} className="mw-100" alt="Notes" /></div>
                                
                                <p><strong>Reminder</strong></p>
                                
                                <p>Reminder can be set by selecting this option</p>
                                
                                <div className="text-center"><img src={Notes5} className="mw-100" alt="Notes" /></div>
                                
                                <p>You can set the date &amp; time in this section</p>
                                
                                <div className="text-center"><img src={Notes6} className="mw-100" alt="Notes" /></div>
                                
                                <p><strong>Share</strong></p>
                                
                                <p>You can share files &amp; folders with anybody with anyone who has an email ID using this option. You can also choose if the person can only Edit/View/or Both.</p>
                                
                                <div className="text-center"><img src={Notes7} className="mw-100" alt="Notes" /></div>
                                
                                <p><strong>Delete</strong></p>
                                
                                <p>Notes can be delete from your notebook using this option. Once deleted, they will not be synced into your account</p>
                                
                                <div className="text-center"><img src={Notes8} className="mw-100" alt="Notes" /></div>
                                
                                <p><strong>Edit</strong></p>
                                
                                <p>Need to edit your notes? No problem. Simply click on this option and you will start right where you left off.</p>
                                
                                <div className="text-center"><img src={Notes9} className="mw-100" alt="Notes" /></div>
                            </Col>

                            <Col xs={12} sm={3} className={classNames("text-left", "ques-bk")}>
                                <Title level={4} className={classNames("ques-title")}>
                                <Icon type="menu" className="ques-icon"/>Recently viewed articles</Title>
                                
                                <RightMenu />
                            </Col>
                        </Row>
                        
                    </Grid>
                </Grid>
                </Zoom>
                {((localStorage.getItem('user_signId')>0) ? '' : <Footer />)}
            </div>
       );
   }
}

export default Ebox;