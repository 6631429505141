import React from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { Typography, Icon, Divider, Breadcrumb } from 'antd';
import classNames from 'classnames';
import Footer from '../footer';

/*typography */
const { Title } = Typography;
/*typography */

class Privacypolicy extends React.Component
{
   render()
   {
       return(
            <div className="wrapper">
                <Grid fluid className="search-bk">
                    <Grid container>
                        <Row>
                            <Col xs={12} sm={12}>
                                <Title level={4} className={classNames("search-title")}>Privacy Policy</Title>
                                <div className="bread-crumb mt-10 mb-10">
                                    <Breadcrumb>
                                        <Breadcrumb.Item><a href="/">Home</a></Breadcrumb.Item>

                                        <Breadcrumb.Item>Getting Started</Breadcrumb.Item>

                                        <Breadcrumb.Item><a href="/">Privacy Policy</a></Breadcrumb.Item>
                                    </Breadcrumb>
                                </div>
                            </Col>
                        </Row>
                    </Grid>
                </Grid>
                <Grid fluid className={classNames("search-btm mt-20 mb-20 col-p")}>
                    <Grid container>
                        <Row>
                            <Col xs={12} className={classNames("text-left")}>
                                <Title level={4} className={classNames("search-subtitle pb-0")}><Icon type="menu" className="icon-style"/>Privacy Policy</Title>
                                <Divider />
                                <p>At FileDrive, We value your privacy and respect your interest in knowing how firedrive collects and uses information about you. This policy explains how we gather, uses and discloses personal and other information about our websites.</p>
                                <p>This Privacy Policy also defines your options about our use of your personal information and how this information can be accessed and updated. When we refer to "Services," we mean the site and the services available by you.</p>
                                <Title level={3}>How we collect your information</Title>
                                <ul>
                                    <li><b>Direct information collected: </b>We collect personal data directly when you register with us. We collect your name, email address, and password, as well as other information that you want to share, such as job information, demographic information, and profile image. We will also collect information that you share with us through or in connection with the Services.</li>
                                    <li><b>Information collected automatically: </b>Other information automatically collected by us using and technologies. For example, we automatically collect your domain name, type of browser, type of device and operating system; page views and links you click on within the Services; IP address, device ID or other identifiers; and location information within the server. We may combine this information with other information that we have collected about you, including your user name, name and other personal information. </li>
                                </ul>
                                <Title level={3}>How the informations are used</Title>
                                <b>Without your consent, we will not use the information:</b>
                                <ul>
                                    <li>To communicate with you via email and other methods regarding your use of the Services; this may include responding to your enquiries, fulfilling your orders or providing technical support.</li>
                                    <li>To display in order to offer location customization and personalized help and instructions, and otherwise to personalize your experience with the Services.</li>
                                    <li>For direct marketing and promotional purposes for example to send newsletters, special offers and promotions. </li>
                                    <li>To protect the Services; for example, to investigate and enforce violations of our policies and conditions of use or other abuses of the Services.</li>
                                    <li>To better understand how users access and use our Services to improve our Services and for other research and analytical purposes. </li>
                                    <li>In order to comply with the law or the legal procedure.</li>
                                </ul>
                                <p><b>Sensitive data: </b>We have no control over whether you include Sensitive Data in your Content. However, we do not intentionally collect or request Sensitive Data from you.If any such collected will be informed to our designated contact within our company who will access the processing of such data. </p>
                                <Title level={3}>How your informations are shared</Title>
                                <ul>
                                    <li>Your information collected will be kept confidential, we will not share any of your information to the third parties for the purpose of marketing without your consent. </li>
                                    <li>We collect employers or customers' information to provide our services to them. Content that you choose to share with, or make available to, other users is shared as designated by you, and you should consider that it may be further shared by your partners; we are not responsible for, or apply to, the collection, use, processing or sharing of Content by other users in this manner.</li>
                                    <li>We may share information about you with third party vendors, consultants and other service providers who work on our behalf or provide services to us.</li>
                                    <li>We release information about you if we believe that we must do so in order to comply with the law, bankruptcy proceedings or similar legal procedures.</li>
                                    <li>Information about you such as name, contact information and billing information, to enforce our agreements with you to protect the rights and safety of FileDrive. </li>
                                    <li>Aggregate or anonymized information about users will be shared to the third parties for marketing, advertising, research or similar purposes.</li>
                                </ul>
                                <Title level={3}>How we update and access to your personal information</Title>
                                <ul>
                                    <li>Company users may log in and use the Account Administration settings or contact the Account Administrator to access or update account profile information. </li>
                                    <li>FileDrive is not in a position to change or delete any information – personal or otherwise – contained in the Content. To request access to this information, you should contact the company that owns the relevant content.</li>
                                </ul>
                                <Title level={3}>How to close your account</Title>
                                <p>If you wish to close your account, you can do so by emailing us at support@FileDrive.com. If you share any Content or information with other users through our Services, such Content or information will continue to be accessible to such users.</p>
                                <p>We will retain your account information and content as long as your account is active and otherwise necessary for our legitimate business purposes.</p>
                                <Title level={3}>Marketing Choices</Title>
                                <p>Customers may opt out of being contacted by us for marketing or promotional purposes by following the opt-out instructions in the emails we send or by emailing. If you do not wish to receive them, you may cancel your account by emailing us. </p>
                                <Title level={3}>Contact</Title>
                                <p>Questions regarding this Policy or our privacy practices should be directed to privacy@FileDrive.com.  If you have any unsolved privacy or data usage concerns that we have not addressed satisfactorily, please contact.</p>
                            </Col>
                        </Row>
                        
                    </Grid>
                </Grid>
                <Footer />
            </div>
       );
   }
}

export default Privacypolicy;